import React, { useState } from "react";
import "./App.css";
import Logo from "./logo.png";
import qr from "./qr.jpg";

import GifSlider from "./GifSlider";

function App() {
  const [input, setInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => setIsOpen(!isOpen);

  const handleSearch = async () => {
    setLoading(true);
    setError(null);

    try {
      setImages([]);

      const response = await fetch(
        "https://sheets.googleapis.com/v4/spreadsheets/1kssaXVSvKrr17PrO0a_bKgjR2NGRYXLtGoeI-rWxkZ0/values/h1!A1:B100?alt=json&key=AIzaSyDn4pC-k3u1DOcPn0eVIK9PoCNXdzth3Rg"
      );
      const data = await response.json();

      const words = input.toLowerCase().trim().split(" ");
      const imgs = [];

      for (let word of words) {
        const row = data.values.find((row) => row[0].toLowerCase() === word);
        if (row) imgs.push(row[1]);
      }

      setImages(imgs);

      const plural = words.length !== 1;

      if (imgs.length === 0)
        setError(
          `La${plural ? "s" : ""} palabra${plural ? "s" : ""} no existe${
            plural ? "n" : ""
          } o no está${plural ? "n" : ""} en la base de datos :(`
        );
    } catch (error) {
      console.log({ error });
      setError("Ocurrió un error al cargar, por favor intente nuevamente.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="app">
        <img src={Logo} className="logo" alt="Signatelli" />
        <h1>Buscar</h1>
        <form>
          <div className="search-container">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Ingresá una palabra"
            />
            <button
              type="submit"
              onClick={handleSearch}
              disabled={loading || input.trim() === ""}
            >
              Buscar
            </button>
          </div>
        </form>
        {loading && <div className="loading">Cargando...</div>}
        {error && <div className="error">{error}</div>}
        {images.length > 0 && <GifSlider images={images} />}
      </div>

      <button className="info" onClick={togglePopup}>
        ?
      </button>
      {isOpen && (
        <div className="popup-backdrop" onClick={togglePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <h2 className="popup-title">¿Quiénes somos?</h2>
            <p className="popup-text">
              Signatelli es un proyecto web ideado por alumnos de 6°C del
              Colegio EMEI, en Río Grande, Tierra del Fuego. Tiene como objetivo
              conectar las herramientas tecnológicas actuales con la educación
              de Lengua de Señas Argentina. Surge a partir del abandono que,
              bajo nuestra perspectiva, sufre esta lengua tan necesaria y útil
              para muchas personas; Signatelli es, entonces, la plataforma
              pensada para facilitar a los alumnos de todos los niveles, asi
              como a los docentes, el acceso a imagenes de calidad y al
              aprendizaje rápido, sencillo e intuitivo.
            </p>
            <img src={qr} alt="" className="popup-image" />
            <button className="close-button" onClick={togglePopup}>
              Cerrar
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default App;
