import React, { useState, useEffect } from 'react';

const GifSlider = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [loopCount, setLoopCount] = useState(0);
  const [isPlaying, setIsPlaying] = useState(true);

  useEffect(() => {
    if (!isPlaying) return; // Stop the interval if not playing

    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => {
        const nextIndex = prevIndex + 1;
        if (nextIndex === images.length) {
          if (loopCount === 4) {
            setIsPlaying(false);
            return images.length - 1; // Stay on the last image
          }
          setLoopCount((prevCount) => prevCount + 1);
          return 0;
        }
        return nextIndex;
      });
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [images, isPlaying, loopCount]);

  const handleReplay = () => {
    setCurrentIndex(0);
    setLoopCount(0);
    setIsPlaying(true);
  };

  return (
    <div>
      <img className='result-image' src={images[currentIndex]} alt="" />
      {!isPlaying && <button onClick={handleReplay}>Reproducir de nuevo</button>}
    </div>
  );
};

export default GifSlider;
